@import "../../assets/style/main";

.golf-scorecard {
    display: grid;
    grid-template-columns: 63px repeat(4, 1fr);
    border: 2px solid $ultra_light_grey;
    border-bottom: 0px;
    width: 100%;

    @include breakpoint(1024px) {
        grid-template-columns: repeat(23, 1fr);
        border-radius: 16px;
        overflow: hidden;
    }

    span {
        height: 40px;
        text-align: center;
        line-height: 40px;
        display: block;
        font-size: 12px;
        font-weight: 700;
        border: 1px solid $ultra_light_grey;

        @include breakpoint(1024px) {
            border: none;
            border-bottom: 2px solid $ultra_light_grey;
        }

        &.sm {
            font-size: 8px;
        }
    }

    &__tabs {
        @include breakpoint-reverse(767px) {
            .react-tabs__tab-list {
                width: calc(100% + 22px);
                overflow-x: auto;
            }
            .react-tabs__tab h4 {
                white-space: nowrap;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}

.golf-scorecard__table {
    width: 100%;
    border-spacing: 0;
    overflow: hidden;
    border-radius: 16px;

    @include breakpoint-reverse(1023px) {
        width: 965px;
    }

    td {
        height: 40px;
        text-align: center;
        align-items: center;
        line-height: 14px;
        font-size: 12px;
        font-weight: 700;
        padding: 0;
        border-bottom: 2px solid $white;
        width: 47px;

        &:first-child {
            background-color: $ultra_light_grey;
            display: flex;
            width: auto;
            width: 120px;
            padding-left: 10px;
            padding-right: 8px;
            border-left: 3px solid $ultra_light_grey;
            border-bottom: none;
            text-align: left;
        }

        &:last-child {
            border-right: none;
            background-color: $blue_700;
            color: $white;
        }

        &:nth-child(11),
        &:nth-last-child(2) {
            background-color: $ultra_light_grey;
        }

        span {
            font-size: 8px;
            margin-left: auto;
            padding-left: 4px;
        }

        &.indent-cell {
            padding-left: 22px;
            min-width: 110px;
        }
    }

    tr:last-child td {
        border-bottom: 0px;
    }

    th {
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 12px;
        font-weight: 700;
        padding: 0;
        background-color: $blue_700;
        color: $white;
        border-bottom: 2px solid $white;

        &:first-child {
            text-align: left;
            padding-left: 10px;
            width: 120px;
        }
    }

    &.small-table {
        width: auto;
        // margin: 0 auto;

        @include breakpoint-reverse(1023px) {
            width: 484px;
        }

        th:first-child,
        td:first-child {
            width: 125px;
        }

        td:nth-last-child(3),
        th:nth-last-child(3) {
            // width: auto;
        }
    }
}

.golf-past-event__scorecard {
    width: 100%;
    overflow: hidden;
    border-spacing: 0;
    border-radius: 16px;
    border: 2px solid $ultra_light_grey;

    @include breakpoint-reverse(1023px) {
        overflow-x: scroll;
        display: block;
    }

    th {
        background-color: $ultra_light_grey;
        font-weight: 800;
        font-size: 16px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: $ultra_dark_grey;
        border: none;
        padding: 0;

        &:first-child {
            text-align: left;
            padding-left: 20px;
        }

        &:last-child {
            background-color: $blue_600;
            color: $white;
        }
    }

    td {
        font-weight: 400;
        font-size: 16px;
        height: 40px;
        text-align: center;
        color: $ultra_dark_grey;
        border-top: 2px solid $ultra_light_grey;

        @include breakpoint(1024px) {
            width: 74px;
        }

        &:first-child {
            text-align: left;
            padding-left: 20px;
            display: flex;
            align-items: center;
            padding-right: 20px;
            // border-left: 2px solid $ultra_light_grey;
            @include breakpoint(1024px) {
                width: auto;
            }
        }

        &:last-child {
            background-color: $blue_600;
            color: $white;
            border-top: 2px solid $ultra_light_grey;
        }

        span {
            font-size: 12px;
            color: $dark_grey;
            font-weight: 700;
            margin-left: auto;
        }
    }
}

.golf-past-event__simple-scorecard {
    @extend .golf-past-event__scorecard;

    th {
        font-weight: 800;

        &:last-child {
            padding-right: 20px;
        }

        &:last-child {
            background-color: $ultra_light_grey;
        }
    }

    td {
        &:first-child {
            width: 40px;
            display: table-cell;
            text-align: center;
            padding-right: 60px;
        }
        &:nth-child(2) {
            width: auto;
            text-align: left;
        }
        &:nth-last-child(2) {
            width: 80px;
        }
        &:last-child {
            background-color: $white;
            width: 100px;
            padding-right: 20px;
        }

        &.golf-scorecard__location {
            height: 60px;

            span {
                font-weight: 400;
                // line-height: 22px;
                // display: block;
                margin-top: 4px;
            }

            i {
                font-style: normal;
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }
}

.golf-team-scorecard {
    @extend .golf-past-event__simple-scorecard;

    th {
        &:nth-child(2) {
            text-align: left;
        }
        &:last-child {
            white-space: nowrap;
        }
    }

    td {
        &:nth-last-child(2) {
            width: auto;
        }
    }
}
