@import '../../assets/style/main';

.search-filter {
  direction: rtl;
  position: relative;
  & > * {
    direction: ltr;
  }
  &__search-wrapper {
    position: relative;
    @include breakpoint(1280px) {
      width: 360px;
    }

    .golf-icon {
      position: absolute;
      top: 8px;
      right: 18px;

      &.close {
        border-radius: 50%;
        &:hover {
          @media (any-hover: hover) {
            cursor: pointer;
            background-color: $ultra_light_grey;
          }
        }
      }
    }
  }
  &__content {
    width: 350px;
    max-height: 400px;
    border-radius: 16px;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
    background-color: $white;
    position: absolute;
    left: -16px;
    top: 20px;
    margin-top: 22px;
    z-index: 1000;

    @include breakpoint(1024px) {
      left: -32px;
      top: 40px;
    }

    h4 {
      margin: 12px 0;
      padding: 0 20px 0 20px;
    }

    p {
      padding: 12px;
    }
    ul {
      li {
        color: $dark_grey;
        padding: 6px 20px;
        font-weight: bold;
        cursor: pointer;

        &:hover {
          @media (any-hover: hover) {
            background-color: $ultra_light_grey;
          }
        }
      }
      &:last-of-type {
        margin-bottom: 20px;
      }
    }
  }

  &__search.golf-input {
    font-size: 14px;

    &::placeholder {
      font-weight: 800;
      color: $medium_grey;
    }
  }
}

.golf-header__search .search-filter__search.golf-input {
  padding-left: 0;
}
