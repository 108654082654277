//Colors
// TM Color palette
$ultra_light_grey: #eeefef;
$lighter_grey: #dedede;
$light_grey: #c1c5c8;
$medium_grey: #8a8c8e;
$dark_grey: #5b6670;
$ultra_dark_grey: #333f48;

$orange_800: #d53500;
$orange_700: #ff6633;
$orange_600: #ff7c51;
$orange_500: #ff9876;
$orange_400: #ffae93;
$orange_300: #ffc7b4;
$orange_200: #ffddd2;
$orange_100: #ffeae3;

$blue_800: #003365;
$blue_700: #005486;
$blue_600: #2872a3;
$blue_500: #518ebc;
$blue_400: #79aad1;
$blue_300: #a2c5e3;
$blue_200: #cadef1;
$blue_100: #f2f7fc;

// Tag colors
$orange_tag_1: #ff6633;
$blue_tag_1: #00a0ce;
$blue_tag_2: #005486;
$red_tag_1: #ea1600;

// Status colors
$error: #ff3c3c;
$error_2: #ff6157;
$yellow_warning: #ffc755;
$green_1: #67c297;
$green_2: #009806;

// Other Colors
$blue_facebook: #4267b2;
$white: #fff;

//Font
$proxima: 'Proxima Nova';
$proxima_cond: 'Proxima Nova Cond';
