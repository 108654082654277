@import "../../assets/style/main";

.placeholder-container,
.text-row,
.text-block,
.rect-shape {
    @keyframes placeholder-pulse {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    animation: placeholder-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    &.no-animation {
        animation: none;

        & * {
            animation: none;
        }
    }
}

.golf-card__horizontal-placeholder {
    &__container {
        gap: 8px;
        @include breakpoint(1280px) {
            gap: 20px;
        }
        .rect-shape {
            height: 80px !important;
            border-radius: 16px;
            margin-right: 0 !important;
            @include breakpoint(1024px) {
                height: 108px !important;
            }
            @include breakpoint(1280px) {
                height: 140px !important;
                max-width: 177px;
            }
        }

        .text-row {
            margin-top: 0 !important;
            height: 16px !important;
            margin-bottom: 4px;
            &:first-child {
                height: 18px !important;
                margin-bottom: 8px;
                @include breakpoint(1280px) {
                    height: 28px !important;
                }
            }
        }
        .btn-placeholder {
            display: none;
            @include breakpoint(1280px) {
                display: block;
                height: 40px !important;
            }
        }
    }
}

.golf-card__profile-placeholder__container {
    .rect-shape {
        width: 100px !important;
        height: 140px !important;
        border-radius: 16px;

        @include breakpoint(640px) {
            width: 250px !important;
            height: 140px !important;
        }
    }

    .text-container-placeholder {
        position: relative;
        min-width: 0;
        flex: 1;

        .bottom-text-placehloder {
            position: absolute;
            bottom: 0;
        }
    }
}

.golf-card__vertical-placeholder {
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    max-width: 275px;
    flex-direction: column;

    @include breakpoint(1420px) {
        max-width: 100%;
    }
}
