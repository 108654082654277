h1 {
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    @include breakpoint(480px) {
        font-size: 48px;
    }

    @include breakpoint(1680px) {
        font-size: 64px;
        line-height: 1;
    }

    &.page-title {
        font-size: 32px;
        line-height: 40px;

        @include breakpoint(480px) {
            font-size: 40px;
            line-height: 48px;
        }

        &-sm {
            @include breakpoint(480px) {
                font-size: 32px;
                line-height: 40px;
            }
        }
    }
}

h2 {
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;

    @include breakpoint(1024px) {
        font-size: 40px;
        line-height: 48px;
    }

    &.headline-sm {
        @include breakpoint(480px) {
            font-size: 32px;
            line-height: 40px;
        }
    }
}

h3 {
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;

    @include breakpoint(1024px) {
        font-size: 32px;
        line-height: 40px;
    }

    &.mobile-heading {
        @include breakpoint(1024px) {
            font-size: 24px;
            line-height: 32px;
        }
    }

    &.golf-homepage__h3 {
        @include breakpoint-reverse(767px) {
            font-size: 32px;
            line-height: 40px;
        }
    }
}

h4 {
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
}

h5 {
    font-size: 16px;
    line-height: 24px;
}

p {
    font-size: 16px;
    line-height: 24px;

    b {
        font-weight: 800;
    }

    &.caption,
    .caption {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        @include breakpoint(480px) {
            line-height: 20px;
        }

        &.sm-height {
            line-height: 16px;
        }
    }

    &.sm-height {
        line-height: 20px;
    }

    &.sm-mobile {
        @include breakpoint-reverse(767px) {
            font-size: 12px;
            line-height: 16px;
            font-weight: 700;
        }
    }

    &.txt-xl {
        font-size: 20px;
        line-height: 32px;
    }

    &.tablet\:txt-xl {
        @include breakpoint(1024px) {
            font-size: 20px;
            line-height: 32px;
        }
    }

    &.txt-large,
    .txt-large {
        font-size: 20px;
        line-height: 28px;
    }
}

label {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    @include breakpoint(480px) {
        line-height: 20px;
    }

    &.caption {
        font-size: 12px;
    }
}

.golf__link {
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: $orange_700;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.golf-social-link {
    height: 40px;
    width: 40px;
    flex: 0 0 40px;
    border: 2px solid $ultra_dark_grey;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

a {
    color: inherit;
    text-decoration: none;
}

input[type="button"] {
    border: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $orange_700;
    color: $white;

    &:hover,
    &:focus,
    &:active {
        outline: none;
    }

    &:hover {
        background: $orange_500;
    }

    &:active {
        background: $orange_800;
    }

    &:disabled,
    &[disabled] {
        background: $ultra_light_grey;
        color: $medium_grey;
    }
}
