@import '../../assets/style/main';

.golf-input {
  border: 1px solid $lighter_grey;
  border-radius: 4px;
  width: 100%;
  padding: 8px 16px;
  font-weight: normal;
  color: $ultra_dark_grey;
  transition: all 0.3s ease-in-out;

  @include placeholder {
    font-weight: normal !important;
    color: $light_grey;
  }

  &.large-font {
    font-size: 32px;
    line-height: 32px;
    padding: 8px;
  }

  &.width-sm {
    width: 176px;
  }

  &.is-error {
    color: $error;
    border: 1px solid $error;
  }

  &.grey-bg {
    background: $ultra_light_grey;
    border-color: $ultra_light_grey;
  }

  &:focus {
    border: 1px solid $orange_700;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $ultra_light_grey;
    color: $light_grey;
  }
}

.golf-container__with-icon {
  position: relative;

  @include placeholder {
    font-weight: bold;
    color: $light_grey;
  }

  &.large-font {
    font-size: 32px;
    line-height: 32px;
    padding: 8px;
  }

  &.width-sm {
    width: 176px;
  }

  .golf-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &.has-right-icon {
    .golf-input {
      padding-right: 52px;
    }
    .golf-icon {
      right: 16px;
    }
  }

  &.has-left-icon {
    .golf-input {
      padding-left: 52px;

      &.pl-22 {
        padding-left: 22px;
      }
    }
    .golf-icon {
      left: 16px;

      &.left-8 {
        left: 8px;
      }
    }
  }
}

.upload__image-wrapper {
  .image-item {
    @include breakpoint-reverse(1023px) {
      display: flex;
      align-items: center;
    }
    @include breakpoint(1024px) {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
      position: relative;
    }

    img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
      @include breakpoint(1024px) {
        width: 100%;
        // height: auto;
        height: 100%;
      }
    }

    .caption {
      @include breakpoint-reverse(1023px) {
        font-size: 16px;
        line-height: 20px;
        font-weight: 800;
        color: $blue_700;
        margin-left: 20px;
      }

      @include breakpoint(1024px) {
        color: $white;
        text-align: center;
        width: 100%;
        background-color: $ultra_dark_grey;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 3px 0 11px;
      }
    }
  }
}

.golf-dropdown {
  &.is-error {
    .golf-dropdown__control {
      border-color: $error;
    }
    .golf-dropdown__single-value {
      color: $error;
    }
  }

  &.autocomplete {
    .golf-dropdown__dropdown-indicator {
      display: none;
    }
    .golf-dropdown__clear-indicator {
      padding: 2px 0px 2px 0;
    }
  }

  &__control {
    border: 1px solid $lighter_grey;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
  }

  &__single-value {
    font-weight: bold;
    color: $ultra_dark_grey;
  }

  &__input-container {
    display: none;
  }

  &--is-disabled {
    .golf-dropdown__placeholder {
      color: $light_grey;
    }
    .golf-dropdown__control {
      background: $ultra_light_grey;
    }
  }

  &__menu {
    z-index: 9;
  }

  &.phone-prefix {
    flex: 0 0 94px;
    margin-right: 8px;

    .golf-dropdown__control {
      padding: 8px 10px;
    }

    .golf-dropdown__menu {
      width: 300px;
    }

    .golf-dropdown__input input {
      font-size: 14px;
      font-weight: normal;
      color: $ultra_dark_grey;
    }

    .golf-dropdown__placeholder {
      font-weight: bold;
      color: $light_grey;
    }

    .golf-dropdown__indicator {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
}

.golf-radio {
  position: relative;

  input[type='radio'],
  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  &.pill-btn {
    label {
      border: 2px solid $ultra_light_grey;
      border-radius: 8px;
      width: 100%;
      padding: 8px 16px;
      font-weight: bold;
      color: $light_grey;
      display: block;
    }

    .check {
      position: absolute;
      top: 0;
      right: 14px;
      transform: translateY(-40%) translateX(50%);
      display: none;
    }

    input[type='radio'],
    input[type='checkbox'] {
      &:checked + label {
        border-color: $orange_700;
        color: $orange_700;
      }

      &:checked ~ svg {
        display: block;
      }
    }
  }

  &.rounder-pill-btn {
    label {
      border: 1px solid $ultra_light_grey;
      border-radius: 20px;
      width: fit-content;
      padding: 8px 16px;
      font-weight: 400;
      display: block;
      text-align: center;
    }

    .check {
      position: absolute;
      top: 0;
      right: 14px;
      transform: translateY(-40%) translateX(50%);
      display: none;
    }

    input[type='radio'],
    input[type='checkbox'] {
      &:checked + label {
        border-color: $orange_700;
        color: $orange_700;
      }
    }
  }

  &.check-btn {
    display: flex;
    align-items: center;
    cursor: pointer;

    .check-info__box {
      border: 2px solid $orange_700;
      border-radius: 50%;
      display: flex;
      width: 20px;
      height: 20px;

      &.grey-border {
        border-color: $light_grey;
      }
    }

    .golf-icon {
      display: none;
    }

    input:checked + .check-info__box {
      border: none;
    }

    input:checked + .check-info__box .golf-icon {
      display: block;
    }
  }
}

.golf-checkbox {
  position: relative;
  input:not(.initial-input) {
    position: absolute;
    top: 0;
    opacity: 0;
    width: 0.01em;
    height: 0.01em;
  }

  .golf-card__package {
    cursor: pointer;

    .golf-icon {
      display: none;
    }
  }

  &.disabled {
    .golf-card__package {
      cursor: not-allowed !important;
      background: $ultra_light_grey;
    }
  }

  input:checked + .golf-card__package .golf-icon {
    display: block;
  }

  input:checked + .golf-card__package,
  input:checked + .golf-card__addon {
    background: $white;
    border-color: $orange_700;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);

    .check,
    .users,
    .radio-check {
      display: block;
    }

    .radio-check {
      background-color: $orange_700;
      border-width: 0px;
    }
  }

  .golf-card__payment {
    opacity: 0.4;
    cursor: pointer;
  }

  input:checked + .golf-card__payment-wrapper .golf-card__payment {
    opacity: initial;
    color: $white;
    background: linear-gradient(360deg, #005486 0%, #79aad1 100%);
    padding: 16px;
    border: 0px;

    .check {
      display: block;
    }
    p.expiry-date {
      color: $blue_400;
    }

    .btn-remove.d-none {
      display: block;
    }
  }

  &.check-info {
    display: flex;
    align-items: center;
    cursor: pointer;

    .check-info__box {
      border: 2px solid $blue_600;
      border-radius: 4px;
      display: flex;
      width: 20px;
      height: 20px;
      flex: 0 0 20px;

      &.grey-border {
        border-color: $light_grey;
      }

      &.radio-btn-style {
        border-radius: 50%;
      }
    }

    .golf-icon {
      display: none;
    }

    input:checked + .check-info__box {
      border: none;
    }

    input:checked + .check-info__box .golf-icon {
      display: block;
    }
  }
}

.golf-pills {
  &__wrapper {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    width: calc(100% + 20px);
    flex-wrap: wrap;
  }

  &__item {
    input:checked + p {
      border-color: $blue_600;
      background: $white;
      color: $ultra_dark_grey;
    }

    .initial-input {
      padding: 6px 14px;
      background-color: $ultra_light_grey;
      color: $light_grey;
      border-radius: 8px;
      font-weight: 800;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid $ultra_light_grey;
      cursor: pointer;
      height: 100%;

      &:active,
      &:focus {
        border-color: $blue_600;
        background: $white;
        color: $ultra_dark_grey;

        &::placeholder {
          color: transparent;
        }
      }
    }
  }

  &__checkbox {
    padding: 6px 0;
    background-color: $ultra_light_grey;
    color: $light_grey;
    border-radius: 8px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $ultra_light_grey;
    cursor: pointer;
  }
}

.golf-textarea {
  border: 2px solid $ultra_light_grey;
  border-radius: 8px;
  width: 100%;
  resize: none;
  padding: 8px 16px;
  font-weight: bold;
  color: $ultra_dark_grey;
  height: 111px;

  @include placeholder {
    font-weight: bold;
    color: $light_grey;
  }

  &:focus,
  &:active {
    outline: none;
  }
}

.golf-slider-input {
  > div:first-child {
    width: 66% !important;
  }
  .golf-input {
    width: 33% !important;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 12px;
}

.golf-autocomplete {
  &.hidden {
    .wrapper {
      div:nth-child(2) {
        display: none;
      }
    }
  }

  .wrapper {
    input {
      font-weight: bold;
    }
    div:nth-child(2) {
      ul {
        li {
          font-weight: 800;
          color: #818181;
          background-color: #fff;
          font-size: 16px;
          line-height: 20px;
          padding-top: 6px;
          padding-bottom: 6px;
          padding-left: 0;
          padding-right: 0;
          outline: none;
          cursor: pointer;

          &:hover {
            @media (any-hover: hover) {
              background-color: #dde1ea;
            }
          }
        }
      }
    }
  }
}

.golf-simple-select {
  border: none;
  text-align-last: right;
  background: none;

  &.search__element-event {
    padding-right: 32px;
    appearance: none;
    position: relative;
    background-image: url('../../assets/images/icons/arrow-light-grey.svg');
    background-position-x: 100%;
    background-position-y: -2px;
    background-repeat: no-repeat;

    @include breakpoint(768px) {
      display: none;
    }
  }
}
