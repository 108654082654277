@import "../../assets/style/main";

.golf-dropdown__profile {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: $white;
    z-index: 10000;
    position: absolute;
    width: 260px;
    background-color: $ultra_dark_grey;
    max-height: 0;
    overflow: hidden;
    padding: 0;

    /* touchscreens */
    @media (hover: none) and (pointer: coarse) {
        max-height: 500px;
        overflow: visible;
        display: none;

        &.is-active {
            display: flex;
        }
    }

    @include breakpoint-reverse(767px) {
        display: none;
        &.is-active {
            display: none;
        }
    }

    .golf-dropdown__links-wrapper {
        padding: 11px 28px 8px 22px;
        // row-gap: 20px;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;

        &:after {
            bottom: 100%;
            right: 25px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-top: 0px;
            border-left: 18px solid transparent;
            border-right: 18px solid transparent;
            border-bottom: 18px solid $ultra_dark_grey;

            @include breakpoint(768px) {
                transform: translateY(1px);
            }

            @include breakpoint(1024px) {
                transform: translateY(0);
            }
        }
    }

    hr {
        border: 1px solid #4f4f4f;
        margin: 10px 0;
    }

    .dot {
        height: 8px;
        width: 8px;
        background-color: $error;
        border-radius: 50%;
        display: inline-block;
    }

    ul {
        width: 100%;
        li {
            padding-top: 10px;
            padding-bottom: 10px;
            &:last-child {
                padding-bottom: 10px;
            }
            &.golf-dropdown__list-item,
            button {
                color: $white;
                font-weight: 800;
            }

            a {
                width: 100%;
                display: inline-block;
            }
        }
    }
}

.golf-dropdown__notifications {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-height: 0;
    overflow-y: scroll;
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;

    @include breakpoint(1280px) {
        top: 88px;
        left: auto;
        right: 0;
        width: 360px;
        border-radius: 16px;
        background-color: $white;
        box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
    }

    &.is-active {
        max-height: 100vh;
        @include breakpoint(1280px) {
            max-height: 510px;
        }
    }

    /* Hide scrollbar */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari and Opera, */
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .golf-empty--notification {
        figure {
            background-image: url("../../assets/images/not-found-small.svg");
            @include backgroundImage(contain);
            width: 125px;
            height: 80px;
            margin: 0 auto;
        }
    }
}

.golf-dropdown__notification-item {
    border-bottom: 2px solid $ultra_light_grey;

    &.is-unread {
        background-color: $blue_100;
    }

    button.close-btn {
        position: absolute;
        top: 16px;
        right: 20px;
    }
}
