@font-face {
    font-family: "Proxima Nova";
    src: url("./../../fonts/ProximaNova-BoldIt.eot");
    src: url("./../../fonts/ProximaNova-BoldIt.eot?#iefix") format("embedded-opentype"), url("./../../fonts/ProximaNova-BoldIt.woff2") format("woff2"),
        url("./../../fonts/ProximaNova-BoldIt.woff") format("woff"), url("./../../fonts/ProximaNova-BoldIt.ttf") format("truetype"),
        url("./../../fonts/ProximaNova-BoldIt.svg#ProximaNova-BoldIt") format("svg");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./../../fonts/ProximaNova-Bold.eot");
    src: url("./../../fonts/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), url("./../../fonts/ProximaNova-Bold.woff2") format("woff2"),
        url("./../../fonts/ProximaNova-Bold.woff") format("woff"), url("./../../fonts/ProximaNova-Bold.ttf") format("truetype"),
        url("./../../fonts/ProximaNova-Bold.svg#ProximaNova-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./../../fonts/ProximaNova-Light.eot");
    src: url("./../../fonts/ProximaNova-Light.eot?#iefix") format("embedded-opentype"), url("./../../fonts/ProximaNova-Light.woff2") format("woff2"),
        url("./../../fonts/ProximaNova-Light.woff") format("woff"), url("./../../fonts/ProximaNova-Light.ttf") format("truetype"),
        url("./../../fonts/ProximaNova-Light.svg#ProximaNova-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./../../fonts/ProximaNova-Extrabld.eot");
    src: url("./../../fonts/ProximaNova-Extrabld.eot?#iefix") format("embedded-opentype"), url("./../../fonts/ProximaNova-Extrabld.woff2") format("woff2"),
        url("./../../fonts/ProximaNova-Extrabld.woff") format("woff"), url("./../../fonts/ProximaNova-Extrabld.ttf") format("truetype"),
        url("./../../fonts/ProximaNova-Extrabld.svg#ProximaNova-Extrabld") format("svg");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./../../fonts/ProximaNova-Regular.eot");
    src: url("./../../fonts/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("./../../fonts/ProximaNova-Regular.woff2") format("woff2"),
        url("./../../fonts/ProximaNova-Regular.woff") format("woff"), url("./../../fonts/ProximaNova-Regular.ttf") format("truetype"),
        url("./../../fonts/ProximaNova-Regular.svg#ProximaNova-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./../../fonts/ProximaNova-Black.eot");
    src: url("./../../fonts/ProximaNova-Black.eot?#iefix") format("embedded-opentype"), url("./../../fonts/ProximaNova-Black.woff2") format("woff2"),
        url("./../../fonts/ProximaNova-Black.woff") format("woff"), url("./../../fonts/ProximaNova-Black.ttf") format("truetype"),
        url("./../../fonts/ProximaNova-Black.svg#ProximaNova-Black") format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./../../fonts/ProximaNova-RegularIt.eot");
    src: url("./../../fonts/ProximaNova-RegularIt.eot?#iefix") format("embedded-opentype"), url("./../../fonts/ProximaNova-RegularIt.woff2") format("woff2"),
        url("./../../fonts/ProximaNova-RegularIt.woff") format("woff"), url("./../../fonts/ProximaNova-RegularIt.ttf") format("truetype"),
        url("./../../fonts/ProximaNova-RegularIt.svg#ProximaNova-RegularIt") format("svg");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./../../fonts/ProximaNova-LightIt.eot");
    src: url("./../../fonts/ProximaNova-LightIt.eot?#iefix") format("embedded-opentype"), url("./../../fonts/ProximaNova-LightIt.woff2") format("woff2"),
        url("./../../fonts/ProximaNova-LightIt.woff") format("woff"), url("./../../fonts/ProximaNova-LightIt.ttf") format("truetype"),
        url("./../../fonts/ProximaNova-LightIt.svg#ProximaNova-LightIt") format("svg");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Cond";
    src: url("./../../fonts/ProximaNovaCond-SemiboldIt.eot");
    src: url("./../../fonts/ProximaNovaCond-SemiboldIt.eot?#iefix") format("embedded-opentype"), url("./../../fonts/ProximaNovaCond-SemiboldIt.woff2") format("woff2"),
        url("./../../fonts/ProximaNovaCond-SemiboldIt.woff") format("woff"), url("./../../fonts/ProximaNovaCond-SemiboldIt.ttf") format("truetype"),
        url("./../../fonts/ProximaNovaCond-SemiboldIt.svg#ProximaNovaCond-SemiboldIt") format("svg");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Cond";
    src: url("./../../fonts/ProximaNova-RegularIt_1.eot");
    src: url("./../../fonts/ProximaNova-RegularIt_1.eot?#iefix") format("embedded-opentype"), url("./../../fonts/ProximaNova-RegularIt_1.woff2") format("woff2"),
        url("./../../fonts/ProximaNova-RegularIt_1.woff") format("woff"), url("./../../fonts/ProximaNova-RegularIt_1.ttf") format("truetype"),
        url("./../../fonts/ProximaNova-RegularIt_1.svg#ProximaNova-RegularIt") format("svg");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Cond";
    src: url("./../../fonts/ProximaNovaCond-Regular.eot");
    src: url("./../../fonts/ProximaNovaCond-Regular.eot?#iefix") format("embedded-opentype"), url("./../../fonts/ProximaNovaCond-Regular.woff2") format("woff2"),
        url("./../../fonts/ProximaNovaCond-Regular.woff") format("woff"), url("./../../fonts/ProximaNovaCond-Regular.ttf") format("truetype"),
        url("./../../fonts/ProximaNovaCond-Regular.svg#ProximaNovaCond-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Cond";
    src: url("./../../fonts/ProximaNovaCond-LightIt.eot");
    src: url("./../../fonts/ProximaNovaCond-LightIt.eot?#iefix") format("embedded-opentype"), url("./../../fonts/ProximaNovaCond-LightIt.woff2") format("woff2"),
        url("./../../fonts/ProximaNovaCond-LightIt.woff") format("woff"), url("./../../fonts/ProximaNovaCond-LightIt.ttf") format("truetype"),
        url("./../../fonts/ProximaNovaCond-LightIt.svg#ProximaNovaCond-LightIt") format("svg");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Cond";
    src: url("./../../fonts/ProximaNovaCond-RegularIt.eot");
    src: url("./../../fonts/ProximaNovaCond-RegularIt.eot?#iefix") format("embedded-opentype"), url("./../../fonts/ProximaNovaCond-RegularIt.woff2") format("woff2"),
        url("./../../fonts/ProximaNovaCond-RegularIt.woff") format("woff"), url("./../../fonts/ProximaNovaCond-RegularIt.ttf") format("truetype"),
        url("./../../fonts/ProximaNovaCond-RegularIt.svg#ProximaNovaCond-RegularIt") format("svg");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Cond";
    src: url("./../../fonts/ProximaNovaCond-Light.eot");
    src: url("./../../fonts/ProximaNovaCond-Light.eot?#iefix") format("embedded-opentype"), url("./../../fonts/ProximaNovaCond-Light.woff2") format("woff2"),
        url("./../../fonts/ProximaNovaCond-Light.woff") format("woff"), url("./../../fonts/ProximaNovaCond-Light.ttf") format("truetype"),
        url("./../../fonts/ProximaNovaCond-Light.svg#ProximaNovaCond-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Cond";
    src: url("./../../fonts/ProximaNova-SemiboldIt.eot");
    src: url("./../../fonts/ProximaNova-SemiboldIt.eot?#iefix") format("embedded-opentype"), url("./../../fonts/ProximaNova-SemiboldIt.woff2") format("woff2"),
        url("./../../fonts/ProximaNova-SemiboldIt.woff") format("woff"), url("./../../fonts/ProximaNova-SemiboldIt.ttf") format("truetype"),
        url("./../../fonts/ProximaNova-SemiboldIt.svg#ProximaNova-SemiboldIt") format("svg");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Cond";
    src: url("./../../fonts/ProximaNova-Semibold.eot");
    src: url("./../../fonts/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"), url("./../../fonts/ProximaNova-Semibold.woff2") format("woff2"),
        url("./../../fonts/ProximaNova-Semibold.woff") format("woff"), url("./../../fonts/ProximaNova-Semibold.ttf") format("truetype"),
        url("./../../fonts/ProximaNova-Semibold.svg#ProximaNova-Semibold") format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Cond";
    src: url("./../../fonts/ProximaNovaCond-Semibold.eot");
    src: url("./../../fonts/ProximaNovaCond-Semibold.eot?#iefix") format("embedded-opentype"), url("./../../fonts/ProximaNovaCond-Semibold.woff2") format("woff2"),
        url("./../../fonts/ProximaNovaCond-Semibold.woff") format("woff"), url("./../../fonts/ProximaNovaCond-Semibold.ttf") format("truetype"),
        url("./../../fonts/ProximaNovaCond-Semibold.svg#ProximaNovaCond-Semibold") format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Industry";
    src: url("./../../fonts/IndustryTest-Thin.otf") format("opentype");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Industry";
    src: url("./../../fonts/IndustryTest-ThinItalic.otf") format("opentype");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Industry";
    src: url("./../../fonts/IndustryTest-Light.otf") format("opentype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Industry";
    src: url("./../../fonts/IndustryTest-LightItalic.otf") format("opentype");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Industry";
    src: url("./../../fonts/IndustryTest-Book.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Industry";
    src: url("./../../fonts/IndustryTest-BookItalic.otf") format("opentype");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Industry";
    src: url("./../../fonts/IndustryTest-Medium.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Industry";
    src: url("./../../fonts/IndustryTest-MediumItalic.otf") format("opentype");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Industry";
    src: url("./../../fonts/IndustryTest-Demi.otf") format("opentype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Industry";
    src: url("./../../fonts/IndustryTest-DemiItalic.otf") format("opentype");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Industry";
    src: url("./../../fonts/IndustryTest-Bold.otf") format("opentype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Industry";
    src: url("./../../fonts/IndustryTest-BoldItalic.otf") format("opentype");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Industry";
    src: url("./../../fonts/IndustryTest-Black.otf") format("opentype");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Industry";
    src: url("./../../fonts/IndustryTest-BlackItalic.otf") format("opentype");
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Industry";
    src: url("./../../fonts/IndustryTest-Ultra.otf") format("opentype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Industry";
    src: url("./../../fonts/IndustryTest-UltraItalic.otf") format("opentype");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("./../../fonts/OpenSans-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("./../../fonts/OpenSans-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("./../../fonts/OpenSans-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("./../../fonts/OpenSans-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("./../../fonts/OpenSans-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("./../../fonts/OpenSans-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("./../../fonts/OpenSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("./../../fonts/OpenSans-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("./../../fonts/OpenSans-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("./../../fonts/OpenSans-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("./../../fonts/OpenSans-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("./../../fonts/OpenSans-ExtraBoldItalic.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

body,
body > * {
    font-family: "Open Sans", "Proxima Nova", -apple-system, Arial, sans-serif;
    letter-spacing: -0.02em;
    color: #232323;
}

//footer stick to bottom
html,
body,
#root,
.App {
    height: 100%;
    @media not all and (min-resolution: 0.001dpcm) {
        /* Safari specific styles go here */
        height: auto;
        min-height: 100vh;
    }
}

.App {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    // overflow-x: hidden;
}
