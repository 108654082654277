@import '../../assets/style/main';

.golf-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  //overflow-y: scroll;
  overflow-x: hidden;
  background: rgba($ultra_dark_grey, 0.85);
  display: none;
  align-items: center;

  &.is-active {
    display: block;
    // padding-top: 60px;

    @include breakpoint(768px) {
      // padding-bottom: 60px;
    }
  }

  &__flex-wrapper {
    height: auto;
    min-height: 100%;
    display: flex;
    align-items: center;

    &.signin-overlay {
      @include breakpoint-reverse(1279px) {
        align-items: flex-end;
        overflow-y: hidden;
      }

      .golf-modal__inner-box {
        @include keyframes(slideUp) {
          0% {
            transform: translateY(100%);
          }
          100% {
            transform: translateY(0);
          }
        }
        @include breakpoint-reverse(1279px) {
          margin-bottom: 0;
          transform: translateY(100%);
          @include animation(0.3s cubic-bezier(0.4, 0, 0.6, 1) forwards slideUp);
          margin-left: 0;
          margin-right: 0;
          max-width: 100%;
          border-radius: 8px 8px 0 0;
          padding-bottom: 24px;
          padding-left: 0;
          padding-right: 0;
        }
      }

      .golf-form {
        @include breakpoint-reverse(1279px) {
          max-height: calc(100vh - 104px);
          overflow-y: scroll;
        }
      }

      form {
        @include breakpoint-reverse(1279px) {
          max-width: 327px;
          margin: 0 auto;
        }
      }

      .golf-form__title-wrapper {
        @include breakpoint-reverse(1279px) {
          position: sticky;
          top: 0;
          width: 327px;
          margin-left: auto;
          margin-right: auto;
          background: #fff;
          z-index: 9;
          padding-bottom: 16px;

          &:after {
            content: '';
            height: 0;
            width: 100vw;
            border-bottom: 1px solid $ultra_light_grey;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      .lg-width-btn {
        @include breakpoint-reverse(1279px) {
          width: 100%;
          max-width: 327px;
          margin: 0 auto;
        }
      }
    }
  }

  &__inner-box {
    width: 100%;
    max-width: 690px;
    margin: 60px auto;
    border-radius: 16px;
    padding: 20px 22px;
    background: $white;
    z-index: 9999;

    @include breakpoint(768px) {
      padding: 40px 60px;
      margin: 60px auto;
    }

    &.sm-width {
      @include breakpoint(1024px) {
        max-width: 459px;
        padding: 32px 24px;
      }
    }

    &.full-height__mobile {
      @include breakpoint-reverse(767px) {
        margin-bottom: 0;
        padding-bottom: 24px;
      }
      @include breakpoint(1024px) {
        padding-bottom: 0;
      }
    }

    &.lg-width {
      @include breakpoint(1024px) {
        max-width: 964px;
      }
    }

    &.p-40 {
      padding: 40px;
    }

    &.sm_tablet\:p-40 {
      @include breakpoint(768px) {
        padding: 40px;
      }
    }
  }

  .close-modal {
    position: absolute;
    top: 22px;
    right: 22px;
    z-index: 99;

    @include breakpoint(768px) {
      top: 34px;
      right: 40px;
    }
  }

  .payment-form {
    display: flex;
    flex-wrap: wrap;
  }

  &__search {
    @extend .golf-modal;
    background: $white;
    overflow-y: scroll;

    &.is-active {
      padding: 20px 22px;
    }

    .close-modal {
      top: 24px;
      right: 22px;
    }

    &--input {
      .search-filter__search-wrapper {
        width: 100%;
      }
      .search-filter__search.golf-input {
        padding-left: 16px !important;
        padding-right: 12px;
        background-color: $ultra_light_grey;
        border-color: $ultra_light_grey;
        color: $ultra_dark_grey;
        &::placeholder {
          color: $ultra_dark_grey;
          font-weight: 700;
        }
      }
    }
  }

  &__remove-payment {
    @extend .golf-modal;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    @include breakpoint(1280px) {
      align-items: center;
      justify-content: center;
    }

    .golf-modal__inner-box {
      margin: 0;
      max-width: unset;
      width: 100%;
      overflow-y: hidden;
      @include breakpoint(768px) {
        padding: 40px 24px;
      }

      @include breakpoint-reverse(1279px) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        animation: payment-modal-slide-up 0.3s cubic-bezier(0.4, 0, 0.6, 1) forwards;
      }

      @include breakpoint(1280px) {
        max-width: 379px;
      }
    }
  }

  &__refineResults {
    @extend .golf-modal;
    &.is-active {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    background: $white;
    padding: 20px 22px 0;

    .close-modal {
      top: 24px;
      right: 22px;
    }

    &--footer {
      box-shadow: 0px -1px 0px $ultra_light_grey;
      width: 100vw;
      left: -22px;
      position: relative;
      padding: 12px 22px;
    }
  }

  &__datepicker {
    @extend .golf-modal;
    background: $white;
    max-width: 100vw;
    display: grid !important;
    place-items: center;

    &.is-active {
      padding: 20px 22px;
    }

    .close-modal {
      top: 24px;
      right: 22px;
    }
    &--inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;

      input {
        width: 100px;
        font-weight: bold;
      }
    }

    .date-range-picker-container {
      @include breakpoint-reverse(1024px) {
        flex-direction: column;
        height: initial;
        padding: initial;
      }
    }
  }

  &__notification {
    @extend .golf-modal;
    background: $white;
    overflow-y: scroll;

    .close-modal {
      position: relative;
      top: 0;
      right: 0;
    }

    .golf-modal__title {
      font-size: 24px;
      line-height: 32px;
      font-weight: 800;
    }
  }

  &__scorecard {
    @extend .golf-modal;

    .close-modal {
      top: 24px;
      right: 22px;

      @include breakpoint(768px) {
        top: 40px;
        right: 40px;
      }
    }

    .golf-scorecard__tabs {
      @include breakpoint-reverse(1023px) {
        overflow-x: scroll;
        width: calc(100% + 40px);
      }
      @include breakpoint-reverse(767px) {
        width: calc(100vw - 22px);
      }
    }
  }

  &__profile-menu {
    .golf-header__nav-menu-btn {
      position: absolute;
      top: 22px;
      right: 22px;
      z-index: 99;

      @include breakpoint(768px) {
        top: 34px;
        right: 40px;
      }
    }
  }

  &__filters {
    @extend .golf-modal;

    h4 {
      margin-bottom: 16px;
    }

    .golf-modal__inner-box {
      max-width: 664px;
      padding: 20px;
    }

    .close-modal {
      top: 24px;
      right: 22px;

      .close {
        background-color: black;
        border-radius: 4px;

        &:hover {
          background-color: $dark_grey;
        }
      }
    }

    label {
      color: black;
      font-size: 14px;
      margin-top: 16px;
    }

    .search-filter {
      div:last-child {
        margin-left: 0;
      }

      p {
        font-size: 14px;
      }
    }
  }

  // TODO: Ask David to help fix modal scroll
  &.round-payment-registration-modal {
    // padding-top: 400px;
    .payments-container {
      padding-top: 20px;
      // max-height: 240px;
    }
  }

  &.add-card-modal {
    @include breakpoint-reverse(767px) {
      .golf-modal__flex-wrapper {
        align-items: flex-start;
      }
      .golf-modal__inner-box {
        margin-top: 0;
        margin-bottom: 0;
      }
      .close-modal {
        z-index: 99999;
        top: 25px;
      }
      .card-form__card {
        color: $light_grey;
        background: $white;
        box-shadow: none;
        padding: 0;
        border-radius: 0;

        label {
          color: $light_grey;
          span {
            line-height: 20px;
          }
        }
      }
    }
  }
}
