@import '../../assets/style/main';

.golf-footer {
  background-color: $ultra_light_grey;
  padding-top: 40px;
  margin-top: auto;

  @include breakpoint-reverse(767px) {
    padding-bottom: 50px;
  }

  &__logo {
    @include backgroundImage(contain);
    background-image: url('../../assets/images/Logo-white.svg');
    width: 143px;
    height: 44px;
  }

  &__logo--orange {
    @include backgroundImage(contain);
    background-image: url('../../assets/images/logo-ggs.png');
    width: 180px;
    height: 50px;
  }

  &__list-title {
    margin-top: 10px;
    margin-bottom: 4px;
    letter-spacing: 0;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__link {
    display: block;
    width: fit-content;
    margin-top: 10px;
    transition: all 280ms cubic-bezier(0.25, 0.1, 0.25, 1);
    color: $dark_grey;
  }

  &__links-wrapper {
    @include breakpoint-reverse(767px) {
      width: 100%;

      li:first-child {
        width: 50%;
      }
    }
  }

  &__newsletter {
    border-top: 1px solid $blue_700;
    padding-top: 20px;

    @include breakpoint(768px) {
      border-top: none;
      padding-top: 0;
    }
  }

  &__subscribe-form {
    display: flex;
    flex-direction: column;
  }

  &__subscribe-label {
    display: none;

    @include breakpoint(768px) {
      font-weight: 600;
      color: $white;
      font-size: 12px;
      display: block;
    }
  }

  &__subscribe-input {
    background-color: $blue_800;
    border-radius: 8px;
    border: 2px solid $blue_700;
    padding: 10px 16px;
    color: $white;

    @include placeholder() {
      color: $white;
      opacity: 0.5;
    }
  }

  &__full-width {
    display: flex;
    align-items: center;
  }
}

.golf-single-event__container.large-footer ~ .golf-footer {
  padding-bottom: 112px;
}

.golf-users__sign-in--bg ~ .golf-footer {
  display: none;
}

.golf__one-click-registration ~ .golf-footer,
.golf-single-event__registration ~ .golf-footer {
  @include breakpoint-reverse(1023px) {
    padding-bottom: 112px;
  }
}
