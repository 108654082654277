.golf-pagination__event-registration {
    position: sticky;
    bottom: 0;
    background-color: $white;
    margin-top: 80px;
    border-top: 1px solid $ultra_light_grey;
    display: flex;
    padding: 20px 0;
    justify-content: space-between;

    @include breakpoint-reverse(1023px) {
        padding: 15px 0;
        position: fixed;
        width: 100%;
        z-index: 12;
    }

    @include breakpoint-reverse(767px) {
        padding: 12px 0;
        border-top: none;
        box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
    }

    &.mt-auto {
        margin-top: auto;
    }

    .event-registration--steps {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;

        .steps {
            width: 100px;
            height: 4px;
            background-color: $ultra_light_grey;
            display: flex;
        }

        .active-steps {
            background-color: $orange_700;
        }
    }

    .event-registration--buttons {
        display: flex;
        flex: 1;
        justify-content: flex-end;

        @include breakpoint-reverse(1023px) {
            display: grid;
            grid-template-columns: 1fr 1fr;

            &.hide-steps {
                grid-template-columns: 1fr;
            }
        }

        &.is-one-click {
            @include breakpoint-reverse(1023px) {
                display: grid;
                grid-template-columns: 1fr auto;
                column-gap: 10px;
            }
            @include breakpoint-reverse(767px) {
                grid-template-columns: 1fr;
            }
        }
    }
}
