@import "../../assets/style/main";

.golf-slider {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include backgroundImage(cover);
    border-radius: 16px;
    background-size: cover !important;

    &__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        em {
            font-size: 23px;
            font-weight: 800;
        }
    }
}

.golf-header__storySlider {
    height: 200px;
    width: 100vw;
    margin-left: -22px;

    @include breakpoint(768px) {
        border-radius: 8px;
        width: 100%;
        margin-left: 0;
    }

    @include breakpoint(1024px) {
        height: 240px;
    }

    .golf-slider {
        border-radius: 0;
    }

    ul.react-multi-carousel-track {
        height: 100%;
    }
    ul.react-multi-carousel-dot-list {
        margin-bottom: 40px;

        li.react-multi-carousel-dot {
            button {
                width: 11px;
                height: 6px;
                border-radius: 3px;
                box-shadow: none;
                border: none;
                background-color: rgba($white, 0.5);
                position: relative;
                overflow: hidden;

                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    transform: translateX(-100%);
                    border-radius: 3px;
                    background-color: $white;
                }
            }

            &:hover button {
                outline: none;
            }

            &--active button {
                width: 86px;
                outline: none;

                &:after {
                    @include animation(translateX 15000ms linear forwards 1 0ms);
                }
            }
        }
    }
}

.golf-testimonial-slider {
    height: 340px;
    border-radius: 16px;
    width: 100%;

    @include breakpoint(1024px) {
        height: 452px;
    }

    ul.react-multi-carousel-track {
        height: 100%;
    }

    .react-multi-carousel-dot-list {
        // position: relative;
        bottom: 52px;
    }

    .golf-slider {
        border-radius: 0;
        height: 340px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        button {
            border: 2px solid $white;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &--dot {
        width: 40px;
        height: 40px;
        margin: 0 10px;
        overflow: hidden;
        padding: 0;
        border-radius: 0;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        .image-btn {
            width: 40px;
            height: 40px;
            flex: 0 0 40px;
            border-radius: 50%;
            @include backgroundImage(cover);
            opacity: 0.3;
        }

        p {
            position: absolute;
            bottom: -80px;
            white-space: nowrap;
        }

        &.custom-dot--active {
            overflow: visible;

            .image-btn {
                opacity: 1;
            }

            p {
                position: relative;
                bottom: 0;
            }
        }
    }
}

.golf-product-slider {
    margin-left: -10px;

    ul.react-multi-carousel-track {
        height: 100%;
    }

    &__container {
        position: relative;
        width: 100%;
    }

    &__arrows-wrapper {
        position: absolute;
        top: -40px;
        display: flex;
        justify-content: flex-end;
        width: 100%;

        @include breakpoint(1280px) {
            top: -60px;
        }
    }

    &__item--placeholder {
        width: 100%;
        height: 332px;
        background-color: $ultra_light_grey;
        border-radius: 16px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.golf-keen-slider {
    &--dots {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        margin-bottom: 35px;
        .dot {
            width: 11px;
            height: 6px;
            border-radius: 3px;
            box-shadow: none;
            border: none;
            background-color: rgba($white, 0.5);
            position: relative;
            overflow: hidden;
            padding: 0;
            margin-right: 6px;

            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                transform: translateX(-100%);
                top: 0;
                left: 0;
                border-radius: 3px;
                background-color: $white;
            }

            &:hover {
                outline: none;
            }

            &.active {
                outline: none;
                width: 86px;
            }

            &.active:after {
                @include animation(translateX 15000ms linear forwards 1 0ms);
            }
        }
    }
}

[class^="number-slide"],
[class*=" number-slide"] {
    background: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #fff;
    font-weight: 500;
    height: 300px;
    max-height: 100vh;
}
