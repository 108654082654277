@import '../../assets/style/main';

.date-range-picker-container,
.date-picker-container {
  display: block;
  min-width: 100px;
  padding: 0;

  @include breakpoint(767px) {
    min-width: 180px;
  }

  input {
    font-weight: bold;

    @include placeholder {
      font-weight: bold;
      color: $light_grey;
    }
  }

  .react-datepicker-popper {
    z-index: 100;
  }

  .date-range-picker,
  .react-datepicker {
    &__wrapper {
      display: flex;
    }
    &__input {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 100px;

      @include breakpoint(767px) {
        span {
          display: none;
        }
      }
      input {
        font-weight: 800;
        width: 96px;
        color: #a1a1a1;
      }
    }
    &__separator {
      margin: 0 4px 0 -4px;
      @include breakpoint-reverse(1024px) {
        margin: 0 4px 0 0px;
      }
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    font-family: "Open Sans", 'Proxima Nova';
    border: none;
    border-radius: 4px;
    border: 1px solid $ultra_light_grey;

    @include breakpoint-reverse(1024px) {
      box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
    }

    &__triangle {
      display: none;
    }
    &__month {
      margin: 0;
    }
    &__week {
      display: flex;
      margin-left: 0 !important;
      padding: 0 16px;
      width: fit-content;
    }
    &__header {
      border-radius: 16px;
      background-color: #ffffff !important;
      border-bottom: none;
      padding: 20px 0 0 0;
    }
    &__custom-header {
      padding: 20px 12px 0 12px;

      h4,
      h5 {
        margin: 0;
      }
      h5 {
        font-weight: normal;
      }
    }
    &__month-container {
      min-width: 240px;
    }
    &__day-names {
      width: fit-content;
      padding: 0 16px;
      margin: 16px 0 8px 0;
    }
    &__day-name {
      color: $dark_grey;
      line-height: 20px;
      font-size: 16px;
      width: 40px;
      margin: 0;
    }
    &__day {
      color: #172b4d;
      font-size: 16px;
      line-height: 32px;
      margin: 0;
      width: 40px;
      height: 0;
      transition: background-color 0.2s ease-in-out;

      &:has(span) {
        height: 36px;
      }

      span {
        margin: 0;
      }

      &--range-start {
        background-color: $orange_500 !important;
        border-radius: 8px 0 0 8px !important;
      }
      &--range-end {
        background-color: $orange_500 !important;
        border-radius: 0 8px 8px 0 !important;
      }
      &--outside-month {
        background: none;
        color: $light_grey;
      }
      &--disabled {
        color: $light_grey;
      }

      &--keyboard-selected {
        background: none;
      }

      &--in-selecting-range,
      &--in-range {
        border-radius: 0;
        background: $orange_200;
      }

      &--disabled {
        background: $ultra_light_grey;
        color: $medium_grey;
        cursor: not-allowed;

        &:hover {
          background: $ultra_light_grey !important;
        }
      }

      &--selected {
        background-color: $orange_700 !important;
        color: #ffffff;
        border-radius: 8px;

        span {
          color: #ffffff;

          &:after {
            display: none;
          }
        }
      }

      &:hover {
        @media (any-hover: hover) {
          background-color: $orange_200;
        }
      }
    }
    &__month {
      padding-bottom: 18px;
      margin-top: 0;
    }
  }
}

.golf-datepicker {
  &__range {
    background: $orange_200 !important;
    color: #000000 !important;
    border-radius: 0px;
  }
  &__start {
    border-radius: 8px 0 0 8px !important;
  }
  &__end {
    border-radius: 0 8px 8px 0 !important;
  }
  &__start,
  &__end {
    background: $orange_700 !important;

    span {
      color: #ffffff;
      &:after {
        display: none;
      }
    }
  }
  &__today {
    background: $white;

    span {
      position: relative;
      color: $orange_700;

      &:after {
        position: absolute;
        content: '';
        inset: 18px 0 0 0;
        height: 2px;
        background: $orange_700;
      }
    }
  }
}
