.golf-icon {
    @include backgroundImage(contain);
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    display: inline-block;

    &:first-child:not(:last-child):not([class*=" mr-"]):not([class*="no-margin"]) {
        margin-right: 12px;
    }

    &:last-child:not(:first-child):not([class*=" ml-"]):not([class*="no-margin"]) {
        margin-left: 12px;
    }

    &__placeholder-txt {
        line-height: 32px;
        margin-right: 16px;
        font-weight: 400;
        font-size: 20px;
    }

    &.is-xsmall {
        width: 16px;
        height: 16px;
        flex: 0 0 16px;

        &:first-child:not(:last-child):not([class*=" mr-"]):not([class*="no-margin"]) {
            margin-right: 4px;
        }

        &:last-child:not(:first-child):not([class*=" ml-"]):not([class*="no-margin"]) {
            margin-left: 4px;
        }
    }

    &.is-small {
        width: 20px;
        height: 20px;
        flex: 0 0 20px;

        &:first-child:not(:last-child):not([class*=" mr-"]):not([class*="no-margin"]) {
            margin-right: 8px;
        }

        &:last-child:not(:first-child):not([class*=" ml-"]):not([class*="no-margin"]) {
            margin-left: 8px;
        }
    }

    &.is-lg {
        width: 32px;
        height: 32px;
        flex: 0 0 32px;

        &:first-child:not(:last-child):not([class*=" mr-"]):not([class*="no-margin"]) {
            margin-right: 16px;
        }

        &:last-child:not(:first-child):not([class*=" ml-"]):not([class*="no-margin"]) {
            margin-left: 16px;
        }
    }

    &.is-xlg {
        width: 60px;
        height: 60px;
        flex: 0 0 60px;
    }

    &.is-number {
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.no-margin {
        &:first-child:not(:last-child) {
            margin-right: 0;
        }

        &:last-child:not(:first-child) {
            margin-left: 0;
        }
    }

    &__wrapper {
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4px;

        &.has-border {
            border: 2px solid $ultra_light_grey;
        }

        &.facebook-bg {
            background-color: $blue_facebook;
        }

        &:last-child {
            margin-right: 0;
        }

        @include breakpoint(768px) {
            margin-right: 10px;
        }
    }

    &.right {
        transform: rotate(-90deg);
        transform-origin: center center;
    }
    &.left {
        transform: rotate(90deg);
        transform-origin: center center;
    }
    &.up {
        transform: rotate(180deg);
        transform-origin: center center;
    }

    &.mirror-icon {
        transform: rotateY(180deg);
    }

    &.white-icon {
        color: $white;
    }

    &.orange-icon {
        color: $orange_700;
    }

    &.orange-800-icon {
        color: $orange_800;
    }

    &.light-grey-icon {
        color: $light_grey;
    }

    &.ultra-dark-grey-icon {
        color: $ultra_dark_grey;
    }

    &.red-icon {
        color: $error;
    }

    &.blue-600-icon {
        color: $blue_600;
    }

    &.blue-700-icon {
        color: $blue_700;
    }

    &.plus {
        position: relative;
        &:after,
        &:before {
            content: "";
            width: 10px;
            height: 2px;
            border-radius: 2px;
            background-color: $ultra_dark_grey;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            overflow: hidden;
        }

        &:after {
            transform: translateX(-50%) translateY(-50%) rotate(90deg);
            transition: all 0.3s ease-in-out;
        }

        &.is-opened {
            &:after {
                transform: translateX(-50%) translateY(-50%) rotate(0);
                transition: all 0.3s ease-in-out;
            }
        }

        &.secondary-grey-100-icon {
            &:after,
            &:before {
                background-color: $medium_grey;
            }
        }

        &.white-icon {
            &:after,
            &:before {
                background-color: $white;
            }
        }

        &.orange-icon {
            &:after,
            &:before {
                background-color: $orange_700;
            }
        }

        &.ultra-dark-grey-icon {
            &:after,
            &:before {
                background-color: $ultra_dark_grey;
            }
        }

        &.light-grey-icon {
            &:after,
            &:before {
                background-color: $light_grey;
            }
        }

        &.secondary-grey-100-icon {
            &:after,
            &:before {
                background-color: $medium_grey;
            }
        }

        &.blue-600-icon {
            &:after,
            &:before {
                background-color: $blue_600;
            }

            &.has-border {
                border-color: $blue_600;
            }
        }

        &.is-bigger {
            &:after,
            &:before {
                width: 17px;
            }
        }

        &.is-large {
            &:after,
            &:before {
                width: 22px;
            }
        }
    }

    &.close {
        @extend .plus;

        &:after {
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
        }

        &:before {
            transform: translateX(-50%) translateY(-50%) rotate(-45deg);
        }
    }

    &.has-border {
        border-width: 2px;
        border-style: solid;
    }

    &.is-rounded {
        border-radius: 50%;
    }

    &.has-bg-blue-600 {
        background-color: $blue_600;
    }

    &.has-bg-orange-700 {
        background-color: $orange_700;
    }

    &.users {
        background-image: url("../../images/icons/users.svg");
    }

    &.number {
        font-size: 12px;
        line-height: 12px;
        border-radius: 4px;
        text-align: center;
        display: flex;
        align-items: center;

        &.is-red {
            background-color: $error;
        }
    }

    &.hover-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        margin: 0 !important;
        opacity: 0;
    }
}
