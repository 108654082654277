@include keyframes(width) {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@include keyframes(translateX) {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@include keyframes(payment-modal-slide-up) {
    0% {
        max-height: 0;
    }
    100% {
        max-height: 300px;
    }
}
