/**
 * modules/mixins
 * ---
 */
//  SPECIFIC MOBILE FIRST BREAKPOINT MIXIN FOR SCREENS
@mixin breakpoint($args: null) {
    //  CASE IF NO DATA IS PASSED WE SHALL OUTPUT MEDIA QUERY ONLY FOR SCREEN THIS MEANS STYLES WILL BE GLOBALLY AND INHERITABLE BY UPPER BREAKPOINTS
    @if $args==null {
        @media screen {
            @content;
        }
    } @else if $args== "iphoneX" {
        @media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
            @content;
        }
    } @else if $args== "iphone8" {
        @media screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
            @content;
        }
    } @else if $args== "iphone8Plus" {
        @media screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
            @content;
        }
    }

    //  CASE IF DATA IS PASSED WE GENERATE BREAKPOINT FOR PASSED BREAKPOINT
    @else {
        @media screen and (min-width: $args) {
            @content;
        }
    }
}

@mixin touchscreen() {
    @media (pointer: coarse) {
        @content;
    }
}

@mixin breakpoint-reverse($args) {
    @media screen and (max-width: $args) {
        @content;
    }
}

//  BOX SHADOW
@mixin box-shadow($args...) {
    -webkit-box-shadow: $args;
    -moz-box-shadow: $args;
    -ms-box-shadow: $args;
    box-shadow: $args;
}

//  TRANSFORM
@mixin transform($transforms) {
    -webkit-transform: $transforms;
    -moz-transform: $transforms;
    -ms-transform: $transforms;
    transform: $transforms;
}

//  TRANSITION
@mixin transition($transitions) {
    -webkit-transition: $transitions;
    -moz-transition: $transitions;
    -ms-transition: $transitions;
    transition: $transitions;
}

//  CLIP PATH
@mixin clip-path($args...) {
    -webkit-clip-path: $args;
    clip-path: $args;
}

//  GRADIENT
@mixin gradient($args...) {
    background: -webkit-linear-gradient($args);
    background: -o-linear-gradient($args);
    background: -moz-linear-gradient($args);
    background: linear-gradient($args);
}

//  KEYFRAMES MIXIN
@mixin keyframes($keyframes-name) {
    @-webkit-keyframes #{$keyframes-name} {
        @content;
    }

    @-moz-keyframes #{$keyframes-name} {
        @content;
    }

    @-o-keyframes #{$keyframes-name} {
        @content;
    }

    @keyframes #{$keyframes-name} {
        @content;
    }
}

//  ANIMATION MIXIN
@mixin animation($args...) {
    -webkit-animation: $args;
    -moz-animation: $args;
    -o-animation: $args;
    animation: $args;
}

//  PLACEHOLDER
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

// FULLIMAGE
@mixin backgroundImage($size) {
    background-size: $size;
    background-repeat: no-repeat;
    background-position: center center;
}

// Overlay Pseudo Element
@mixin OverlayPseudoElement() {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

@mixin fullWidthBrakeWrapper() {
    width: 100vw;
    margin-right: calc(-1 * var(--wrapper-gutter));
    margin-left: calc(-1 * var(--wrapper-gutter));
}

@mixin resetWidthToWrapper() {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}

// Flexbox display
@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex: $values;
    -webkit-flex: $values;
    -ms-flex: $values;
    flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
    -webkit-flex-direction: $direction;
    -moz-flex-direction: $direction;
    -ms-flex-direction: $direction;
    flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
    -webkit-flex-wrap: $wrap;
    -moz-flex-wrap: $wrap;
    -ms-flex-wrap: $wrap;
    flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
    -webkit-flex-flow: $flow;
    -moz-flex-flow: $flow;
    -ms-flex-flow: $flow;
    flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
    -webkit-box-ordinal-group: $val;
    -moz-box-ordinal-group: $val;
    -ms-flex-order: $val;
    -webkit-order: $val;
    order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
    -webkit-flex-grow: $grow;
    -moz-flex-grow: $grow;
    -ms-flex-grow: $grow;
    flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
@mixin flex-shrink($shrink) {
    -webkit-flex-shrink: $shrink;
    -moz-flex-shrink: $shrink;
    -ms-flex-shrink: $shrink;
    flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
@mixin flex-basis($width) {
    -webkit-flex-basis: $width;
    -moz-flex-basis: $width;
    -ms-flex-basis: $width;
    flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
    -webkit-justify-content: $justify;
    -moz-justify-content: $justify;
    -ms-justify-content: $justify;
    justify-content: $justify;
    -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align) {
    -webkit-align-content: $align;
    -moz-align-content: $align;
    -ms-align-content: $align;
    align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
    -webkit-align-items: $align;
    -moz-align-items: $align;
    -ms-align-items: $align;
    align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($align) {
    -webkit-align-self: $align;
    -moz-align-self: $align;
    -ms-align-self: $align;
    align-self: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin justify-self($align) {
    -ms-grid-column-align: $align;
    justify-self: $align;
}

@mixin rotate($angle) {
    -moz-transform: rotate($angle);
    -ms-transform: rotate($angle);
    -webkit-transform: rotate($angle);
    transform: rotate($angle);
}
