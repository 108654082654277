@for $i from 1 through 16 {
    @for $j from 1 through 16 {
        @if ($i > $j) or ((round(calc($j/$i)) == calc($j/$i)) and not ($j >= $i)) {
            //  DO NOTHING
        } @else {
            .is-#{$i}-of-#{$j} {
                @include breakpoint() {
                    flex: none;
                    width: percentage(calc($i/$j));
                }
            }
        }
    }
}

.w-auto {
    width: auto;
}

//768px
.sm_tablet {
    @for $i from 1 through 16 {
        @for $j from 1 through 16 {
            @if ($i > $j) or ((round(calc($j/$i)) == calc($j/$i)) and not ($j >= $i)) {
                //  DO NOTHING
            } @else {
                &\:is-#{$i}-of-#{$j} {
                    @include breakpoint(768px) {
                        flex: none;
                        width: percentage(calc($i/$j));
                    }
                }
            }
        }
    }
    @include breakpoint(768px) {
        &\:w-auto {
            width: auto;
        }
    }
}

//1024px
.tablet {
    @for $i from 1 through 16 {
        @for $j from 1 through 16 {
            @if ($i > $j) or ((round(calc($j/$i)) == calc($j/$i)) and not ($j >= $i)) {
                //  DO NOTHING
            } @else {
                &\:is-#{$i}-of-#{$j} {
                    @include breakpoint(1024px) {
                        flex: none;
                        width: percentage(calc($i/$j));
                    }
                }
            }
        }
    }
    @include breakpoint(1024px) {
        &\:w-auto {
            width: auto;
        }
    }
}

//1280px
.sm_desktop {
    @for $i from 1 through 16 {
        @for $j from 1 through 16 {
            @if ($i > $j) or ((round(calc($j/$i)) == calc($j/$i)) and not ($j >= $i)) {
                //  DO NOTHING
            } @else {
                &\:is-#{$i}-of-#{$j} {
                    @include breakpoint(1280px) {
                        flex: none;
                        width: percentage(calc($i/$j));
                    }
                }
            }
        }
    }
    @include breakpoint(1280px) {
        &\:w-auto {
            width: auto;
        }
    }
}

.golf-container {
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 22px;
    padding-right: 22px;

    @include breakpoint(768px) {
        padding-left: 40px;
        padding-right: 40px;
    }

    @include breakpoint(1280px) {
        padding-left: 60px;
        padding-right: 60px;
    }

    @include breakpoint(1420px) {
        padding-right: 0;
        padding-left: 0;
    }

    @include breakpoint(1460px) {
        max-width: 1300px;
    }

    &__sm {
        @extend .golf-container;
        max-width: 965px;

        @include breakpoint(1045px) {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

.golf-container-full-width {
    width: 100%;
}
