@import '../../assets/style/main';

button {
  border: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $ultra_dark_grey;
  transition: all 0.2s ease-in-out;

  &.has-icon {
    padding: 8px 16px;
  }

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }

  &.primary-btn {
    background: $orange_700;
    color: $white;

    &:hover {
      @media (any-hover: hover) {
        background: $orange_500;
      }
    }

    &:active {
      background: $orange_800;
    }

    &:disabled,
    &[disabled] {
      background: $ultra_light_grey;
      color: $medium_grey;

      &.is-loading {
        background: $orange_700;
      }
    }

    &.lg-width-btn {
      width: 100%;

      @include breakpoint(768px) {
        width: 176px;
      }
    }

    &.w-264 {
      @include breakpoint() {
        width: 264px;
      }
    }

    &.sm_tablet\:w-auto {
      @include breakpoint(768px) {
        width: auto;
      }
    }
  }

  &.reverse-icon {
    flex-direction: row-reverse;

    .golf-icon {
      &:first-child:not(:last-child) {
        margin-right: 0;
        margin-left: 12px;
      }

      &:last-child:not(:first-child) {
        margin-left: 0;
        margin-right: 12px;
      }

      &.is-small {
        &:first-child:not(:last-child) {
          margin-right: 0;
          margin-left: 8px;
        }

        &:last-child:not(:first-child):not([class*=' ml-']) {
          margin-left: 0;
          margin-right: 8px;
        }
      }

      &.is-lg {
        &:first-child:not(:last-child) {
          margin-right: 0;
          margin-left: 16px;
        }

        &:last-child:not(:first-child) {
          margin-left: 0;
          margin-right: 16px;
        }
      }
    }
  }

  &.primary-full-w-btn {
    @extend .primary-btn;
    width: 100%;

    span:not(.golf-icon):not([class^='css-']) {
      width: 100%;
    }

    .golf-icon {
      &:first-child:not(:last-child) {
        margin-right: auto;
      }

      &:last-child:not(:first-child) {
        margin-left: auto;
      }
    }

    &.sm_desktop\:full-w {
      @include breakpoint(1280px) {
        width: 100%;
      }
    }
  }

  &.primary-full-w-txt-inherit-btn {
    @extend .primary-full-w-btn;
    text-transform: inherit;
  }

  &.primary-responsive-btn {
    @extend .primary-btn;

    @include breakpoint-reverse(1279px) {
      @include breakpoint(1024px) {
        span:not(.golf-icon) {
          display: none;
        }
        .golf-icon {
          &:first-child:not(:last-child) {
            margin-right: 0;
          }

          &:last-child:not(:first-child) {
            margin-left: 0;
          }
        }
      }
    }

    @include breakpoint-reverse(767px) {
      width: 100%;
      .golf-icon {
        &:first-child:not(:last-child) {
          margin-right: auto;
        }

        &:last-child:not(:first-child) {
          // margin-left: auto;
        }
      }
    }
  }

  &.secondary-btn {
    background: $blue_200;
    color: $blue_700;

    &:hover {
      @media (any-hover: hover) {
        background: $blue_100;
      }
    }

    &:active {
      background: $blue_300;
    }

    &:disabled,
    &[disabled] {
      background: $ultra_light_grey;
      color: $medium_grey;
    }

    &.is-1-of-1 {
      width: 100%;
    }

    &.sm_tablet\:w-auto {
      @include breakpoint(768px) {
        width: auto;
      }
    }
  }

  &.secondary-full-w-btn {
    @extend .secondary-btn;
    width: 100%;
  }

  &.secondary-outline-btn {
    background: $white;
    color: $ultra_dark_grey;
    border: 2px solid $ultra_light_grey;
    padding: 6px 14px;

    &:hover {
      @media (any-hover: hover) {
        border-color: $medium_grey;
      }
    }

    &:active {
      border-color: $ultra_dark_grey;
    }

    &.facebook-btn {
      background-color: $blue_facebook;
      border: 2px solid $blue_facebook;
      color: $white;
      &:hover {
        @media (any-hover: hover) {
          background-color: $blue_facebook;
          border: 2px solid $blue_facebook;
          color: $white;
        }
      }
      &:active {
        background-color: $blue_facebook;
        border: 2px solid $blue_facebook;
        color: $white;
      }
    }

    &:disabled,
    &[disabled] {
      border-color: $ultra_light_grey;
      color: $medium_grey;
    }

    &.py-9 {
      padding-top: 9px;
      padding-bottom: 9px;
    }

    &.no-border {
      border-color: transparent;
    }

    &.h-40 {
      height: 40px;
    }

    &.w-108 {
      width: 108px;
    }
  }

  &.secondary-outline-disabled-btn {
    @extend .secondary-outline-btn;
    border-color: $ultra_light_grey;
    color: $medium_grey;
  }

  &.secondary-outline-icon-btn {
    @extend .secondary-outline-btn;
    padding: 6px;
    .golf-icon {
      &:first-child:not(:last-child) {
        margin-right: 0;
      }

      &:last-child:not(:first-child) {
        margin-left: 0;
      }
    }
  }

  &.secondary-outline-icon-bg-grey-btn {
    @extend .secondary-outline-icon-btn;
    background: $ultra_light_grey;
    border: 2px solid $ultra_light_grey;

    .golf-icon {
      &:first-child:not(:last-child) {
        display: block;
      }

      &:last-child:not(:first-child) {
        display: none;
      }
    }

    &:hover {
      @media (any-hover: hover) {
        background: $ultra_dark_grey;
        border: 2px solid $ultra_dark_grey;
        .golf-icon {
          &:first-child:not(:last-child) {
            display: none;
          }

          &:last-child:not(:first-child) {
            display: block;
          }
        }
      }
    }
    &:active {
      background: $ultra_dark_grey;
      border: 2px solid $ultra_dark_grey;
      .golf-icon {
        &:first-child:not(:last-child) {
          display: none;
        }

        &:last-child:not(:first-child) {
          display: block;
        }
      }
    }
  }

  &.secondary-outline-full-w-btn {
    @extend .secondary-outline-btn;
    width: 100%;
    text-align: center;

    span:not(.golf-icon) {
      width: 100%;
    }

    .golf-icon {
      &:first-child:not(:last-child) {
        margin-right: auto;
      }

      &:last-child:not(:first-child) {
        margin-left: auto;
      }
    }
  }

  &.secondary-outline-full-w-reverse-btn {
    @extend .secondary-outline-full-w-btn;
    flex-direction: row-reverse;

    .golf-icon {
      &:first-child:not(:last-child) {
        margin-right: 0;
        margin-left: auto;
      }

      &:last-child:not(:first-child) {
        margin-left: 0;
        margin-right: auto;
      }
    }
  }

  &.secondary-outline-blue-btn {
    background: $white;
    color: $blue_800;
    border: none;
  }

  &.reset-btn {
    border: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-transform: none;
    text-align: left;
    border-radius: 0;
    cursor: pointer;
    padding: 0;
    display: inline-block;
    background: none;

    &.leading-20 {
      line-height: 20px;
    }

    &.normal-text {
      font-size: 16px;
      line-height: 20px;
    }

    &.text-xl {
      font-size: 16px;
      line-height: 24px;
    }

    &.leading-40 {
      line-height: 40px;
    }

    &.color-orange {
      color: $orange_700;
    }

    &.has-icons {
      display: flex;
      align-items: center;
    }

    &.font-extrabold {
      font-weight: 800;
    }

    &.font-bold {
      font-weight: bold;
    }

    &.center {
      display: flex;
      align-items: center;
    }

    &.align-left {
      justify-content: flex-start;

      &.reverse-icon {
        justify-content: flex-end;
      }
    }

    &.text-right {
      text-align: right;
    }

    &:disabled,
    &[disabled] {
      color: $light_grey;

      .golf-icon {
        display: none;
      }
    }

    &.sm_desktop\:d-none {
      @include breakpoint(1280px) {
        display: none;
      }
    }
  }

  &.icon-btn {
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &.circle {
      border-radius: 50%;
      height: 40px;
      width: 40px;

      &.is-small {
        height: 32px;
        width: 32px;
      }
    }

    &.background-white {
      background-color: $white;
    }

    &.has-hover-icon:hover {
      @media (any-hover: hover) {
        .hover-icon {
          opacity: 1;
        }
      }
    }
  }

  &.has-shadow {
    filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.1));
  }

  &.upload__image-btn {
    width: auto;
    height: 64px;
    color: $blue_700;
    background-color: transparent;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    position: relative;

    @include breakpoint(1024px) {
      justify-content: center;
      margin: 0 auto;
    }
    @include breakpoint(1024px) {
      margin-bottom: 20px;
      border-radius: 50%;
      background-color: $blue_100;
      width: 140px;
      height: 140px;
      flex-direction: column;
    }

    &:before {
      content: '';
      display: block;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $blue_100;
      z-index: 0;

      @include breakpoint(1024px) {
        display: none;
      }
    }

    .golf-icon {
      margin-left: 16px;
      z-index: 1;
      @include breakpoint(1024px) {
        margin-left: 0;
        margin-bottom: 4px;
      }
    }
  }

  &.dashed-border {
    border-radius: 16px;
    padding: 20px 18px;
    width: 100%;
    border: 2px dashed $orange_300;
    background-color: $orange_100;
    color: $orange_700;

    &.is-disabled {
      background-color: $ultra_light_grey;
      border: 2px dashed $light_grey;
      color: $light_grey;
      cursor: not-allowed;
      pointer-events: none;
    }

    &:hover {
      @media (any-hover: hover) {
        border: 2px dashed $orange_700;
        background-color: $orange_200;
      }
    }
  }

  &.d-inline-flex {
    display: inline-flex;
  }
}

a.golf__link--button {
  @extend button;
}

.toggle-container {
  width: 40px;
  background-color: $orange_700;
  cursor: pointer;
  user-select: none;
  border-radius: 24px;
  height: 20px;
  position: relative;
  transition: all 0.3s ease;

  &.disabled {
    background-color: $light_grey;
    transition: all 0.3s ease;
    .dialog-button {
      right: 21px;
      transition: all 0.3s ease;
    }
  }
}

.dialog-button {
  cursor: pointer;
  background-color: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 0;
  right: -1px;
  transition: all 0.3s ease;
}

.copy-to-clipboard-button {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  // width: 120px;

  .golf-icon {
    margin-right: 0;
  }

  &__message {
    position: absolute;
    right: 0;
    top: 100%;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 8px;
    margin-top: 8px;
    white-space: nowrap;
  }
}

.add-to-calendar-button {
  background-color: transparent !important;
  padding: 0 !important;
  font-weight: 800;
}

.atcb-list-wrapper {
  .atcb-list {
    transform: translatey(62%);

    width: fit-content;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05) !important;

    .atcb-list-item {
      font-family: 'Open Sans', 'Proxima Nova' !important;
      background-color: $white;
      font-weight: 600;

      &:hover {
        background-color: $ultra_light_grey;
      }
    }
  }
}

#atcb-bgoverlay.atcb-no-bg:has(div) {
  opacity: 1;
}

.atcb-modal {
  .atcb-modal-box {
    font-family: 'Open Sans', 'Proxima Nova';
  }

  .atcb-modal-icon {
    background-color: $white;
    width: 95px;
    height: 95px;
  }

  .atcb-modal-headline {
    background-color: $white;
  }

  .atcb-modal-content {
    background-color: $white;

    button {
      font-family: 'Open Sans', 'Proxima Nova';
      box-shadow: none;
      border: 1px solid $ultra_light_grey;
      border-radius: 8px;
    }

    .atcb-date-btn-left {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      width: auto;
      background-color: $orange_700;
      border-radius: 8px 0 0 8px;

      .atcb-date-btn-month {
        color: $white;
      }
    }

    .atcb-date-btn-right {
      background-color: $white;
      border-radius: 0 8px 8px 0;
    }
  }

  .atcb-modal-buttons {
    background-color: $ultra_light_grey;

    button {
      @extend .secondary-outline-btn;
      font-family: "Open Sans", "Proxima Nova";
      box-shadow: none;
      padding: 6px 14px;
    }

    button:hover {
      box-shadow: none;
    }
  }
}

@keyframes copy-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes copy-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
